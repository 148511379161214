import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AdminLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Check if token exists and is valid
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get('https://footzy.pl:3000/verify-token', {
        headers: { 'Authorization': token }
      })
      .then(() => {
        navigate('/admin/dashboard'); // Redirect if token is valid
      })
      .catch(() => {
        // Token might be expired or invalid, do nothing
      });
    }
  }, [navigate]);

  const handleLogin = () => {
    axios.post('https://footzy.pl:3000/login', { username, password })
      .then(response => {
        localStorage.setItem('token', response.data.token);
        navigate('/admin/dashboard');
      })
      .catch(error => alert('Nieprawidłowe dane logowania'));
  };

  return (
    <div>
      <h2>Logowanie administratora</h2>
      <input 
        type="text" 
        placeholder="Login" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
      />
      <input 
        type="password" 
        placeholder="Hasło" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
      />
      <button onClick={handleLogin}>Zaloguj</button>
    </div>
  );
}

export default AdminLogin;
