import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { WarningAmber, ReportProblem, Info } from '@mui/icons-material'; // Ikony z Material-UI
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { parseISO, isWithinInterval } from 'date-fns';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material';

function HomePage() {
  const [expandedSubject, setExpandedSubject] = useState(null);
  const [planImage, setPlanImage] = useState('');
  const [sessionType, setSessionType] = useState('Zimowy');  // Domyślna wartość
  const [notifications, setNotifications] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [event, setEvent] = useState(null);
  const [timer, setTimer] = useState(null);
  const [weekType, setWeekType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [sortedSubjects, setSortedSubjects] = useState([]);
  const [appointments, setAppointments] = useState([]);
  

  useEffect(() => {
    const now = new Date();
    const threeDaysAgo = new Date(now.getTime() - (1000 * 60 * 60 * 24 * 3)); // Przesunięcie w czasie o 3 dni w tył
  
  
    // Sortuj przedmioty według daty aktualizacji, najnowsze na górze
    const sorted = [...subjects].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  
    setSortedSubjects(sorted.map(subject => {
      const updatedAt = parseISO(subject.updatedAt);
      const justUpdated = isWithinInterval(updatedAt, { start: threeDaysAgo, end: now }); // Sprawdź, czy przedmiot był zaktualizowany w ostatnich 3 dniach
  
      return {
        ...subject,
        justUpdated: justUpdated,
      };
    }));
  }, [subjects]);

  useEffect(() => {
    axios.get('https://footzy.pl:3000/appointments')
      .then(response => setAppointments(response.data.map(appointment => ({
        ...appointment,
        timeLeft: calculateTimeLeft(appointment.date) // Inicjalizacja czasu
      }))))
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setAppointments(currentAppointments => 
        currentAppointments.map(appointment => ({
          ...appointment,
          timeLeft: calculateTimeLeft(appointment.date)
        }))
      );
    }, 1000); // Aktualizacja co sekundę

    return () => clearInterval(interval);
  }, []);


  const calculateTimeLeft = (date) => {
    const now = new Date();
    const targetDate = new Date(date);
    const distance = targetDate - now;
  
    if (distance < 0) {
      return "Czas minął";
    }
  
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    
    return `${days}d ${hours}h ${minutes}m`;
  };
  


  useEffect(() => {
    axios.get('https://footzy.pl:3000/settings/session')
      .then(response => {
        setSessionType(response.data);
      })
      .catch(error => console.log('Błąd podczas pobierania sesji:', error));

    axios.get('https://footzy.pl:3000/notifications')
      .then(response => setNotifications(response.data))
      .catch(error => console.log(error));
    
    axios.get('https://footzy.pl:3000/subjects')
      .then(response => setSubjects(response.data))
      .catch(error => console.log(error));

    axios.get('https://footzy.pl:3000/events')
      .then(response => {
        if (response.data.length > 0) {
          setEvent(response.data[0]);
          startTimer(response.data[0].date);
        }
      })
      .catch(error => console.log(error));

      setWeekType(isEvenWeek());
      // Pobierz odpowiednie zdjęcie planu w zależności od tygodnia
    if (isEvenWeek() === 'Parzysty') {
      setPlanImage('https://footzy.pl:3000/plan/even');
    } else {
      setPlanImage('https://footzy.pl:3000/plan/odd');
    }
  }, []);
  

  

  function isEvenWeek() {
    const threeDays = 3 * 24 * 60 * 60 * 1000; // 3 dni w milisekundach
    const timeCount = Date.now() - threeDays; // odejmujemy 3 dni
    const weekDuration = 7 * 24 * 60 * 60 * 1000; // 1 tydzień w milisekundach
    const weekCount = Math.floor(timeCount / weekDuration); // ile tygodni minęło

    return weekCount % 2 === 0 ? "Parzysty" : "Nieparzysty";
  } 
  

  const startTimer = (eventDate) => {
    const countDownDate = new Date(eventDate).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      if (distance < 0) {
        clearInterval(interval);
        setTimer("Wydarzenie zakończone");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimer(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);
  };

  const scrapeData = () => {
    setShowModal(true);
    const url = 'https://footzy.pl:3000/scrape'; // Adres Twojego backendu

    let foundAnyInfo = false;
    let content = ''; // Zmienna do przechowywania zawartości modalu

    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Błąd w odpowiedzi serwera');
            }
            return response.json();
        })
        .then(data => {
            data.forEach(htmlData => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlData, 'text/html');
                const entries = doc.querySelectorAll('h2');

                entries.forEach(entry => {
                    if (entry.textContent.includes('371')) {
                        foundAnyInfo = true;

                        content += `<h2>Tytuł: ${entry.textContent}</h2>`;

                        let postMeta = entry.nextElementSibling;
                        while (postMeta && postMeta.tagName !== 'P') {
                            postMeta = postMeta.nextElementSibling;
                        }

                        content += `<p>${postMeta ? postMeta.textContent : "Brak elementu <p> dla tego tytułu."}</p>`;

                        let postContentInner = entry.nextElementSibling;
                        while (postContentInner && postContentInner.tagName !== 'DIV') {
                            postContentInner = postContentInner.nextElementSibling;
                        }

                        content += `<p>${postContentInner ? postContentInner.querySelector('p').textContent : "Brak elementu <p> wewnątrz <div> dla tego tytułu."}</p>`;
                        content += '<hr>'; // Separator
                    }
                });
            });

            if (!foundAnyInfo) {
                content = '<h2>Brak informacji.</h2>';
            }

            setModalContent(content);
            // Pokaż modal
        })
        .catch(error => {
            content += `<p>Błąd podczas pobierania danych: ${error.message}</p>`;
            setModalContent(content);
            // Pokaż modal z błędem
        });
};


 // Funkcja do zamykania modalu
 const closeModal = () => {
  setShowModal(false);
};

  // Funkcja do rozwijania szczegółów przedmiotu
  const toggleSubjectDetails = (index) => {
    setExpandedSubject(expandedSubject === index ? null : index);
  };
  // Funkcja do renderowania ikony w zależności od ważności powiadomienia
  const getIconByImportance = (importance) => {
    switch (importance) {
      case 'high':
        return <WarningAmber className="text-red-600 mr-2" />;
      case 'medium':
        return <ReportProblem className="text-yellow-600 mr-2" />;
      default:
        return <Info className="text-gray-600 mr-2" />;
    }
  };

  // Funkcja do renderowania koloru tła w zależności od ważności powiadomienia
  const getBackgroundColorByImportance = (importance) => {
    switch (importance) {
      case 'high':
        return 'bg-red-100';
      case 'medium':
        return 'bg-yellow-100';
      default:
        return 'bg-gray-100';
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Nagłówek */}
      <header className="bg-gradient-to-r from-blue-600 to-blue-900 text-white py-6 shadow-lg">
        {/* Wrapper div to center content */}
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex items-center justify-center space-x-4">
            <img 
              src="logo512.png" 
              alt="Logo" 
              className="h-12 w-12 hover:scale-110 transition-all duration-300" 
            />
            <h1 className="text-2xl lg:text-4xl font-bold tracking-widest">
              GRUPA 371 – Uniwersytet Szczeciński
            </h1>
          </div>
        </div>

        <nav className="bg-blue-800 mt-6 shadow-md">
          <div className="container mx-auto flex justify-center space-x-8 py-4">
          <a
            href="https://pliki.172us.pl"
            className="text-lg text-white hover:text-yellow-300 transition-all duration-300"
          >
            Pliki
          </a>
            <Link
              to="#"
              className="text-lg text-white hover:text-yellow-300 transition-all duration-300"
              onClick={scrapeData} // Call the function on click
            >
              Dziekanat
            </Link>
          </div>
        </nav>
      </header>

      {/* Główna zawartość */}
      <main className="container mx-auto p-6 max-w-6xl space-y-12">

        {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
          <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-11/12 md:w-1/3">
            <h2 className="text-xl font-bold mb-4">Informacje grup</h2>
            <div className="modal-body" dangerouslySetInnerHTML={{ __html: modalContent }} />
            <button
              className="mt-4 bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition"
              onClick={closeModal}
            >
              Zamknij
            </button>
          </div>
        </div>
      )}
        
        {/* Powiadomienia */}
        <section>
          <h2 className="text-3xl font-semibold mb-6 text-blue-900">Powiadomienia</h2>
          <div className="space-y-4">
            {notifications.map((notification, index) => (
              <div 
                key={index} 
                className={`p-5 shadow-lg rounded-lg flex items-center ${getBackgroundColorByImportance(notification.importance)} space-x-4`}>
                {getIconByImportance(notification.importance)}
                <div>
                  <h3 className="text-xl font-bold text-blue-800">{notification.title}</h3>
                  <p className="text-sm text-gray-500">{notification.date} {notification.time}</p>
                  <p>{notification.content}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Terminy */}
        <section>
          <h2 className="text-3xl font-semibold mb-6 text-blue-900">Terminy</h2>
          <div className="space-y-4">
            {appointments.sort((a, b) => new Date(a.date) - new Date(b.date)).map(appointment => (
              <div key={appointment._id} className="p-5 shadow-lg rounded-lg bg-white border-l-4 border-blue-500">
                <div className="flex items-center mb-2">
                  <h3 className="text-xl font-semibold text-blue-700 mr-2">
                    {appointment.title}
                  </h3>
                  <AccessTimeIcon className="text-gray-500" />
                </div>
                <div className="flex items-center text-gray-600 text-sm">
                  <p className="mr-2"><CalendarTodayIcon className="text-blue-500" /></p>
                  {/* Wyświetlenie pełnej daty i samej godziny bez sekund */}
                  <p>{new Date(appointment.date).toLocaleDateString('pl-PL')} o {new Date(appointment.date).toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                <div className="text-gray-600 text-sm mt-2">
                  <p>{appointment.description}</p>
                </div>
                <div className="text-gray-600 text-sm mt-1">
                  <span>Pozostało: </span>
                  <span className="font-semibold">{appointment.timeLeft}</span>
                </div>
              </div>
            ))}
          </div>
        </section>



        {/* Plan Zajęć */}
        <section>
          <h2 className="text-3xl font-semibold mb-6 text-blue-900">Plan Zajęć – Semestr {sessionType}</h2>
          <div className="w-full max-w-4xl mx-auto">
            {/* Wyświetlenie tygodnia */}
            <section>
          <h3 className="text-2xl font-semibold mb-4 text-blue-900">Aktualny tydzień: {weekType}</h3>
        </section>
          <img src={planImage} alt={`Plan tygodnia ${weekType}`} className="w-full h-auto rounded-lg shadow-lg" />
        </div>
        </section>

        {/* Sekcja Przedmiotów */}
<section className="mb-12">
  <h2 className="text-3xl font-semibold mb-6 text-blue-900">Przedmioty</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {sortedSubjects.map((subject, index) => (
      <div
        key={index}
        className={`relative bg-gradient-to-br from-green-100 to-blue-100 p-6 rounded-lg shadow-lg transform transition-transform hover:scale-101 hover:shadow-2xl ${subject.isNew ? 'border border-red-500' : ''}`}
      >
        <div className="relative flex justify-between items-center cursor-pointer" onClick={() => toggleSubjectDetails(index)}>
          <div>
            <h3 className="text-xl font-bold text-gray-800">
              {subject.name} {subject.isNew && <span className="text-red-500">(Nowy)</span>}
            </h3>
            <p className="text-gray-600">Prowadzący: {subject.teacher}</p>
          </div>
          <ExpandMore className={`text-gray-600 transition-transform transform ${expandedSubject === index ? 'rotate-180' : 'rotate-0'}`} />
        </div>
        
        {/* Dodanie kropki, jeśli przedmiot został niedawno zaktualizowany */}
        {subject.justUpdated && (
          <span className="absolute top-2 left-2 bg-red-500 h-3 w-3 rounded-full shadow-md"></span>
        )}

        {expandedSubject === index && (
          <div className="mt-4">
            <p className="text-gray-700" style={{ whiteSpace: 'pre-wrap' }}>
              {subject.details}
            </p>
          </div>
        )}
      </div>
    ))}
  </div>
</section>


{/* Sekcja Wydarzenia */}
{event && (
        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-blue-900">Nadchodzące Wydarzenie</h2>
          <div className="relative overflow-hidden bg-gradient-to-r from-blue-500 to-green-500 rounded-lg p-6 shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl">
            <div className="animate-slide-in-up">
              <h3 className="text-2xl font-bold text-white mb-2">{event.description}</h3>
              <p className="text-lg text-white mb-4">Data: {event.date}</p>
              <div className="text-lg font-semibold text-white">
                Odliczanie: <span className="text-yellow-300">{timer}</span>
              </div>
            </div>
            {/* Dekoracyjny element z animacją */}
            <div className="absolute -bottom-4 -right-4 w-32 h-32 bg-white opacity-10 rounded-full transform rotate-45 animate-pulse"></div>
          </div>
        </section>
        )}

      </main>

      {/* Stopka */}
      <footer className="bg-blue-700 text-white py-6">
        <div className="container mx-auto text-center">
          <p className="text-sm">Strona zrobiona w celach edukacyjnych dla studentów Uniwersytetu Szczecińskiego</p>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
